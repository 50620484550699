import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import SelectMui from '@material-ui/core/Select'
import { styled } from '@mui/system'
import loaderLight from '@assets/images/loader-light.svg'
import { getRegionZone } from '@helpers/geo-location'
import RTGLink from '../../shared/link'
import { titleCase } from '../helpers'
import {
  MainContainer,
  Container,
  StatesContainer,
  StoresContainer,
  State,
  City,
  CityBottomLine,
  StoreContainer,
  Store,
  StoreAddress,
  StorePhone,
  MainHeading,
  MobileStateSelectorWrapper,
} from './styles'
import sortByState from './sortByState'

const regionInPR = getRegionZone().region === 'PR'

const StyledFormControl = styled(FormControl)({
  width: '100%',
  marginBottom: '20px',
})
const StyledSelect = styled(SelectMui)({
  '& .MuiSelect-root': {
    minWidth: '100% !important',
  },
})
const StyledLabel = styled(InputLabel)({
  paddingLeft: '5px',
})

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minWidth: `calc(100% - 100px)`,
    },
  },
}

const SortedStoreList = ({ stores, loading }) => {
  const [selectedState, setSelectedState] = useState(null)
  const sortedStores = sortByState(stores)

  useEffect(() => {
    const storesInPR = stores.filter(a => a.state === 'PR')
    if (regionInPR && storesInPR) {
      setSelectedState('Puerto Rico')
    } else {
      setSelectedState('Alabama')
    }
  }, [stores])

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
        <img alt="loading" src={loaderLight} style={{ filter: 'invert(1)' }} />
      </div>
    )
  }

  return (
    <MainContainer>
      <MainHeading>Complete Store List</MainHeading>
      <Container>
        <StatesContainer>
          {Object.entries(sortedStores)
            .sort((a, b) => {
              if (a[0] === 'Puerto Rico') {
                return 1
              }
              return `${a[0]}`.localeCompare(b[0])
            })
            .map(([state]) => (
              <State key={state} selected={state === selectedState} onClick={() => setSelectedState(state)}>
                {state}
              </State>
            ))}
        </StatesContainer>
        {selectedState && (
          <StoresContainer>
            <MobileStateSelectorWrapper>
              <StyledFormControl variant="outlined">
                <StyledLabel id="state-select">State</StyledLabel>
                <StyledSelect
                  margin="dense"
                  labelId="state-select"
                  id="state-select"
                  onChange={e => setSelectedState(e.target.value)}
                  value={selectedState}
                  label="State"
                  MenuProps={MenuProps}
                >
                  {Object.entries(sortedStores)
                    .sort((a, b) => `${a[0]}`.localeCompare(b[0]))
                    .map(([state]) => (
                      <MenuItem value={state} key={state}>
                        {state}
                      </MenuItem>
                    ))}
                </StyledSelect>
              </StyledFormControl>
            </MobileStateSelectorWrapper>
            {Object.entries(sortedStores[selectedState]?.cities)
              .sort(([a], [b]) => a.localeCompare(b))
              .map(([city, storesArray]) => (
                <>
                  <City key={city}>{city}</City>
                  <CityBottomLine />
                  <StoreContainer>
                    {storesArray.map(store => (
                      <Store key={store.label}>
                        <RTGLink
                          data={{
                            slug: store.storeSlug,
                            category: 'store',
                            action: 'click',
                            label: store.label,
                            textColor: '#0053a0',
                          }}
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            lineHeight: '22px',
                            textDecoration: 'underline',
                            marginBottom: 7,
                          }}
                        >
                          {store.displayName || store.storeName}
                        </RTGLink>
                        <StoreAddress>{titleCase(store.address1)}</StoreAddress>
                        <StoreAddress>
                          {store.City || titleCase(store.city)}, {store.state || store.stateFullName}{' '}
                          {store.Zip || store.zip || store.zipcode}
                        </StoreAddress>
                        <StorePhone>{store.phoneNumber}</StorePhone>
                      </Store>
                    ))}
                  </StoreContainer>
                </>
              ))}
          </StoresContainer>
        )}
      </Container>
    </MainContainer>
  )
}

SortedStoreList.propTypes = {
  stores: PropTypes.object,
  loading: PropTypes.bool,
}

export default SortedStoreList
