import React from 'react'
import { Badge, styled } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Grid from '@mui/material/Grid'
import { colors, fonts, breakPoints } from '@constants/styles'

const StyledDiv = styled('div')({
  outline: `${colors.red} solid 1px`,
  padding: `25px`,
  margin: `0 20px 20px`,
  [`@media screen and (max-width: ${breakPoints.small})`]: {
    margin: `0 0 0 20px`,
  },
})

const StyledSpan = styled('span')({
  color: colors.red,
  fontSize: fonts.txtMedium,
  fontWeight: `bold`,
  display: `inline-block`,
})

const WarningMessage = () => (
  <StyledDiv>
    <Grid container spacing={0.5}>
      <Grid item xs={1}>
        <span>
          <Badge overlap="circular" badgeContent={<ErrorOutlineIcon color="error" />} />
        </span>
      </Grid>
      <Grid item xs={11}>
        <StyledSpan>
          There are no Rooms To Go stores within 100 miles of your search. Please change your ZIP code or filter
          criteria to find stores nearest you.
        </StyledSpan>
      </Grid>
    </Grid>
  </StyledDiv>
)

export default WarningMessage
