import { expandState } from '@helpers/geo-location'

const sortByState = stores =>
  stores.reduce((states, store) => {
    const stateName = expandState(store.state)
    const label = `${store.City}${store.StoreName ? ` - ${store.StoreName}` : ''} ${store.StoreType}`
    states[stateName] = {
      ...(states[stateName] || {}),
      cities: {
        ...(states[stateName]?.cities || {}),
        [store.city]: states[stateName]?.cities[store.city]?.length
          ? [...states[stateName].cities[store.city], { ...store, label, stateFullName: stateName }]
          : [{ ...store, label, stateFullName: stateName }],
      },
    }
    return states
  }, {})

export default sortByState
