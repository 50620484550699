import React, { useState, useEffect } from 'react'
import { arrayOf, number, object, shape, string } from 'prop-types'
import { setupAnalytics } from '@helpers/google-tag-manager'
import { getAllStore } from '@services/stores'

import StoreMap from './store-map'
import SortedStoreList from './sorted-store-list'

const StoreLocator = ({ data }) => {
  const [stores, setStores] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setupAnalytics({ pageData: { type: 'store', title: 'Find a Store', path: '/stores' } })
    getAllStore()
      .then(storeData => {
        setStores(storeData)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        throw new Error({ error, message: 'Error: while fetching stores.' })
      })
  }, [])

  return (
    <div className="storeLocator__page">
      <StoreMap storeLocations={data.stores} />
      <SortedStoreList stores={stores} loading={loading} />
    </div>
  )
}

StoreLocator.propTypes = {
  data: shape({
    stores: arrayOf(
      shape({
        Address1: string,
        Address2: string,
        City: string,
        Id: string,
        Location: shape({
          latLng: shape({
            lat: number,
            lng: number,
          }),
        }),
        PhoneNumber: string,
        State: string,
        StoreHours: object,
        StoreNumber: number,
        StoreName: string,
        StoreType: string,
        Zip: string,
      }),
    ),
  }),
}

export default StoreLocator
