import React from 'react'
import { arrayOf, number, object, shape, string } from 'prop-types'
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react'
import { getLatLng, getCurrentLocation, getRegionZone } from '@helpers/geo-location'
import { getMarkers } from '@helpers/store-locator'
import { letters, weekdays, validateZip } from '@helpers/string-helper'
import StoreInfo from './store-info'
import StoreSearchBar from './search-bar'
import MapLocationsList from './map-locations-list'
import WarningMessage from './warning-message'
import '../../assets/css/components/store-locator/store-locator.sass'

export class StoreMap extends React.Component {
  state = {
    currentLat: 0,
    currentLng: 0,
    invalidZip: false,
    zipInput: '',
    currentDay: '',
    tomorrowDay: '',
    loaded: false,
    activeMarker: {},
    markerObjects: [],
    selectedMarker: {},
    showingInfoWindow: false,
  }

  componentDidMount() {
    const d = new Date()
    const t = new Date()
    t.setDate(d.getDate() + 1)
    this.getInitialCenter(d, t, true)
    this.getInitialCenter(d, t)
  }

  getInitialCenter = (d, t, ip = false) => {
    const regionInPR = getRegionZone().region === 'PR'
    const location = window.navigator && window.navigator.geolocation
    if (!regionInPR && location && !ip) {
      location.getCurrentPosition(
        position => {
          this.setState({
            currentLat: position.coords.latitude,
            currentLng: position.coords.longitude,
            currentDay: weekdays[d.getDay()],
            tomorrowDay: weekdays[t.getDay()],
            loaded: true,
          })
        },
        error => {
          console.error(error)
          this.updateIPCenter(d, t)
        },
      )
    } else {
      this.updateIPCenter(d, t)
    }
  }

  updateIPCenter = (d, t) => {
    const rtg_location = getCurrentLocation()
    if (rtg_location) {
      this.setState({
        currentLat: rtg_location.lat,
        currentLng: rtg_location.long,
        currentDay: weekdays[d.getDay()],
        tomorrowDay: weekdays[t.getDay()],
        loaded: true,
      })
    }
  }

  updateInputZipValue = evt => {
    this.setState({
      zipInput: evt.target.value,
      invalidZip: false,
    })
  }

  changeZip = async () => {
    const { zipInput } = this.state
    if (validateZip(zipInput)) {
      const location = await getLatLng(zipInput)
      if (location.lat) {
        this.setState({
          currentLat: location.lat,
          currentLng: location.lng,
        })
      } else {
        this.setState({
          invalidZip: true,
        })
      }
    } else {
      this.setState({
        invalidZip: true,
      })
    }
  }

  onMarkerMounted = element => {
    if (element && element.marker) {
      this.setState(prevState => ({
        markerObjects: [...prevState.markerObjects, () => element.marker],
      }))
    }
  }

  onLocationClick = (index, marker) => {
    const { markerObjects } = this.state
    const i = parseInt(index)
    if (i > -1 && typeof markerObjects[index] === 'function') {
      this.setState(prevState => ({
        activeMarker: prevState.markerObjects[index](),
        selectedMarker: marker,
        showingInfoWindow: true,
      }))
    }
  }

  onMapClicked = () => {
    const { showingInfoWindow } = this.state
    if (showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      })
    }
  }

  render() {
    const { storeLocations, google } = this.props
    const {
      currentLat,
      currentLng,
      currentDay,
      tomorrowDay,
      activeMarker,
      selectedMarker,
      showingInfoWindow,
      loaded,
      invalidZip,
      zipInput,
    } = this.state
    const markers = getMarkers(currentLat, currentLng, storeLocations)
    return (
      <>
        <h2 className="storeLocator__h2">Store Locator</h2>
        <div className="storeLocator__wrapper">
          <div className="storeLocator__wrapper -leftColumn">
            <StoreSearchBar
              invalidZip={invalidZip}
              zipInput={zipInput}
              updateInputZipValue={this.updateInputZipValue}
              changeZip={this.changeZip}
            />
            {markers[0].distance > 100 ? (
              <WarningMessage />
            ) : (
              <MapLocationsList
                markers={markers}
                onLocationClick={this.onLocationClick}
                currentDay={currentDay}
                tomorrowDay={tomorrowDay}
              />
            )}
          </div>
          {loaded && (
            <Map
              initialCenter={{ lat: currentLat, lng: currentLng }}
              center={{ lat: currentLat, lng: currentLng }}
              className="storeLocator__wrapper -rightColumn"
              google={google}
              zoom={11}
              onClick={this.onMapClicked}
              mapTypeControl={false}
              scaleControl={false}
              streetViewControl={false}
              rotateControl={false}
              fullscreenControl={false}
            >
              <Marker
                position={{ lat: currentLat, lng: currentLng }}
                title="Current Location"
                name="Current Location"
                icon={{
                  url: 'https://maps.google.com/mapfiles/marker.png',
                }}
              />
              {markers[0].distance < 100 &&
                markers.map((mark, index) => (
                  <Marker
                    ref={this.onMarkerMounted}
                    position={{ lat: mark.lat, lng: mark.lng }}
                    title={mark.name}
                    icon={{
                      url: `https://maps.google.com/mapfiles/marker${letters[index]}.png`,
                    }}
                    key={`${mark.name}|${mark.title}`}
                    onClick={() => this.onLocationClick(index, mark)}
                  />
                ))}
              <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
                <StoreInfo selectedMarker={selectedMarker} />
              </InfoWindow>
            </Map>
          )}
          {!loaded && (
            <div className="map">
              <p className="loading">Loading...</p>
            </div>
          )}
        </div>
      </>
    )
  }
}

StoreMap.propTypes = {
  storeLocations: arrayOf(
    shape({
      Address1: string,
      City: string,
      Location: shape({
        latLng: shape({
          lat: number,
          lng: number,
        }),
      }),
      State: string,
      StoreHours: object,
      StoreNumber: number,
      StoreType: string,
      Zip: string,
    }),
  ),
  google: object,
}

export default GoogleApiWrapper({
  apiKey: `${process.env.GATSBY_GOOGLE_BROWSER_API_KEY}`,
})(StoreMap)
