import styled from 'styled-components'
import { breakPoints } from '@constants/styles'

export const MainContainer = styled.div`
  background-color: #fff;
  padding: 42px 32px;
  margin: 20px 0 0 0;
`

export const Container = styled.div`
  display: flex;
  background-color: #fff;
`
export const StatesContainer = styled.div`
  width: 220px;
  border-right: 1px solid #dedede;
  margin-top: 31px;
  @media only screen and (max-width: ${breakPoints.medium}) {
    display: none;
  }
`
export const StoresContainer = styled.div`
  width: 100%;
  margin-left: 48px;
  background-color: #fff;
  margin-top: 31px;
  @media only screen and (max-width: ${breakPoints.medium}) {
    margin-left: 0;
  }
`

export const State = styled.div`
  color: ${props => (props.selected ? '#fff' : '#0053a0')};
  background-color: ${props => (props.selected ? '#0053a0' : '#fff')};
  font-size: 18px;
  line-height: 48px;
  font-weight: ${props => (props.selected ? '600' : '500')};
  cursor: pointer;
  height: 48px;
  padding-left: 23px;
  border-left: ${props => (props.selected ? '10px solid #FFD140' : '10px solid transparent')};
`

export const City = styled.div`
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-transform: uppercase;
`

export const CityBottomLine = styled.div`
  margin: 5px 0;
  border-top: 1px solid #dedede;
`

export const StoreContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: ${breakPoints.medium}) {
    flex-direction: column;
  }
`

export const Store = styled.div`
  width: 215px;
  margin-right: 15px;
  margin-bottom: 20px;
  color: #0053a0;
  @media only screen and (max-width: ${breakPoints.medium}) {
    width: 100%;
  }
`

export const StoreAddress = styled.div`
  font-size: 16px;
  line-height: 22px;
`
export const StorePhone = styled.div`
  font-size: 16px;
  line-height: 22px;
  margin-top: 7px;
`
export const MainHeading = styled.div`
  color: #2e2e38;
  font-size: 21px;
  font-weight: bold;
  line-height: 28px;
`

export const MobileStateSelectorWrapper = styled.div`
  display: none;
  width: 100%;
  @media only screen and (max-width: ${breakPoints.medium}) {
    display: block;
  }
`
